.Change-Payment-Method {
    /* width: 207px; */
    height: 22px;
    /* margin: 9px 70px 23px 25px; */
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.15px;
    text-align: left;
    color: #5f2161;
  }
  .The-fellowship-incurs-additional-fees-related-to-p_mobile {
    /* width: 785px;
    height: 48px; */
    /* margin: 14px 28px 17px 25px; */
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: justify;
    color: rgba(0, 0, 0, 0.87);
    margin:15px;
  }
  .ButtonBG {
    width: 150px;
    height: 40px;
    margin: 0 20px 0 0;
    padding: 0 0.2px 0 0;
    border-radius: 30px;
    border: solid 2px #41b5c2;
    background-color: #41b5c2;
    color: #ffffff;

    font-family: Tungsten;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #ffffff;
  }
  .ButtonBGMobile {
    width: 145px;
    height: 40px;
    /*margin: 0 20px 0 0;*/
    padding: 0 0.2px 0 0;
    border-radius: 30px;
    border: solid 2px #ffffff;
    background-color: #41b5c2;
    color: #ffffff;

    font-family: Tungsten;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #ffffff;
  }

  .mytransaction_changepaymentbutton {
    width: 270px;
    height: 40px;
    /*margin: 0 20px 0 0;*/
    padding: 0 0.2px 0 0;
    border-radius: 30px;
    border: solid 2px #ffffff;
    background-color: #41b5c2;
    color: #ffffff;

    font-family: Tungsten;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #ffffff;
  }

  .mobileViewHeader {
    display: none;
    position: fixed;
    width: 100%;
    z-index: 2;
  }
  .mobile_header {
    width: 100%;
    height: 56px;
    box-shadow: 0 1px 10px 0 rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.12), 0 2px 4px 0 rgba(0,0,0,.14);
    background-color: #543379;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mobile_header_title {
    flex: 1 1;
    margin: 0 20px;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: .5px;
    color: #fff;
  }
  .td_style1_mobile{
    width: 149px;
      height: 32px;
      margin: 0 2px 0 0;
      font-family: Roboto;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.4px;
      text-align: left;
      color: #787885;
      padding: 8px;
  }
  .td_style_mobile{
    width: 194px;
      height: 32px;
      margin: 0 2px 0 0;
      font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.44px;
    text-align: left;
    color: #19191d;
  }

.Last_4_digt_mobile{
  width: 305px;
  border-radius: 4px;
  border: solid 1px #c6cacc;
  float: inline-end;
  /* margin-bottom: -60px; */
  /* margin-bottom: 4px; */
  /* margin-top: 33px; */
  /* margin-top: -38px; */
  margin: auto;
  background-color: #f1f1f1;
}

.grid_row1{
  margin-bottom:60px;
}
.grid_row2{
  margin-top:0px;
}

@media only screen and (max-width: 380px) {
  .Bottom-Blue{
    bottom: 50px;
  }

  
}

@media only screen and (max-width: 325px) {
  .Bottom-Blue{
    bottom: 48px;
  }
 
}

@media only screen and (min-width: 900px) and (max-width: 1000px){
  .Bottom-Blue{
    bottom: 96px;
  }
  .grid_row1{
    margin-bottom:60px;
    margin-left:4%;
    margin-right: 4%;
  }
  .grid_row2{
    margin-top:2%;
  }
 
}
@media screen and (min-width: 765px) and (max-width: 899px) {
  .Bottom-Blue{
    bottom: 86px;
  }
  .grid_row1{
    margin-bottom:60px;
    margin-left:4%;
    margin-right: 4%;
  }
  .grid_row2{
    margin-top:2%;
  }
}
@media screen and (min-width: 1024px)  {
  .Bottom-Blue{
    bottom: 106px;
  }
  .grid_row1{
    margin-bottom:60px;
    margin-left:4%;
    margin-right: 4%;
  }
  .grid_row2{
    margin-top:2%;
  }
}