@media (min-width: 1200px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}
.thank-you {
  margin-top: -20px;
  padding-left: 25px;
  background-color: white;
  margin-top: -26px;
  padding-bottom: 8px;
}
.close-flip-div {
  padding-top: 5px;
}
.close-flip-row {
  margin-top: 0px;
}
.Rectangle a:focus {
  text-decoration: none;
}
.Rectangle a:hover {
  text-decoration: underline;
}
.member-section {
  padding-left: 35px;
  padding-top: 10px;
  background-color: #ffffff;
}

.member-data-head {
  font-family: "Candara", "Segoe UI", Arial, sans-serif;
  color: #808080;
  font-size: 16px;
  line-height: 20px;
}
.member-data-name {
  font-family: "Candara", "Segoe UI", Arial, sans-serif;
  color: #34ab8f;
  font-size: 22px;
  line-height: 22px;
}
.member-data-ID {
  font-family: "Candara", "Segoe UI", Arial, sans-serif;
  color: #34afb0;
  font-size: 22px;
  line-height: 22px;
}
.member-data-label {
  width: 50%;
  font-family: "Candara", "Segoe UI", Arial, sans-serif;
  color: #3f3f3f;
  font-size: 16px;
  line-height: 14px;
}
.member-data-value {
  width: 50%;
  font-family: "Candara", "Segoe UI", Arial, sans-serif;
  color: #8d1342;
  font-size: 18px;
  line-height: 14px;
}

.afa-card .member-data-head {
  font-family: "Candara", "Segoe UI", Arial, sans-serif;
  color: #f7356b;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}
.afa-card .member-data-name {
  font-family: "Candara", "Segoe UI", Arial, sans-serif;
  color: #8c1342;
  font-size: 18px;
  line-height: 22px;
  font-weight: bolder;
  padding-bottom: 3px;
}
.afa-card .member-data-ID {
  font-family: "Candara", "Segoe UI", Arial, sans-serif;
  color: #8c1342;
  font-size: 22px;
  line-height: 22px;
}
.afa-card .member-data-label {
  width: 50%;
  font-family: "Candara", "Segoe UI", Arial, sans-serif;
  color: #420045;
  font-size: 16px;
  line-height: 14px;
  font-weight: bolder;
}
.afa-card .member-data-value {
  width: 50%;
  font-family: "Candara", "Segoe UI", Arial, sans-serif;
  color: #8c1342;
  font-size: 16px;
  line-height: 14px;
  font-weight: bold;
}

.afa-card .document-ID {
  font-family: "Candara", "Segoe UI", Arial, sans-serif;
  color: #000;
  font-size: 13px;
  line-height: 20px;
  font-weight: bolder;
}

.member-note {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  color: #f7356b;
  font-size: 16px;
  line-height: 18px;
  padding-left: 25px;
  background-color: white;
  // padding-bottom: 4px;
  width: 58%;
  margin-top: -35px;
}

.flip-button-div {
  padding: 5px;
  text-align: right;
}

.branding-section {
  // height: 100px;
  padding: 10px 10px 0px 10px;
  padding-top: 5px;
  background-color: #ffffff;
}
.uhs-logo {
  max-height: 80px;
}
.association-logo {
  max-height: 80px;
}
.network-logo {
  max-height: 80px;
}
.uhs-program {
  font-family: "Candara", "Segoe UI", Arial, sans-serif;
  color: #f7356b;
  font-size: 20px;
  line-height: 24px;
}

.footer-section {
  height: 50px;
  padding: 20px;
  background-color: #420046;
  margin-top: 13px;
}

.afa-card .footer-section {
  height: 60px;
  padding-top: 6px;
  margin-top: 0;
  padding: 6px;
}
.footer-details {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  color: #fff;
  font-size: 15px;
  line-height: 15px;
}
.afa-card .footer-details {
  font-family: "Candara", "Segoe UI", Arial, sans-serif;
  color: #fff;
  font-size: 15px;
  line-height: 17px;
  font-weight: bolder;
}

.contact-section {
  height: 130px;
  padding: 20px;
  background-color: #420046;
}
.contact-details {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
}

.message-section {
  padding: 20px 20px 0 20px;
  background-color: #eae8db;
}
.pre-notification {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  color: #000;
  font-size: 15px;
  line-height: 18px;
}

.pre-notification-title {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  color: #000;
  font-size: 17px;
  line-height: 18px;
}
.pre-notification-list {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  padding-left: 30px;
  color: #000;
  font-size: 15px;
  line-height: 18px;
}
.emergencies {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  color: #000;
  font-size: 15px;
  line-height: 20px;
}

.important-message-section {
  height: 50px;
  padding: 20px;
  background-color: #eae8db;
}
.important-message {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  color: #8c1342;
  font-size: 16px;
  line-height: 24px;
  font-weight: bolder;
}

@media (max-width: 768px) {
  .container {
    /* width: 720px; */
    max-width: 100%;
  }

  .Rectangle {
    border-radius: 4px;
    background-color: #f1f1f1;
  }
  .id-div-margin {
    margin-top: 7px;
  }

  .close-flip-div {
    padding-top: 30px;
  }
  .close-flip-row {
    margin-top: -26px;
  }
  .member-data-head {
    font-size: 14px;
    line-height: 15px;
  }
  .member-data-name {
    font-size: 18px;
    line-height: 15px;
  }
  .member-data-ID {
    font-size: 18px;
    line-height: 17px;
  }
  .member-data-label {
    font-size: 16px;
    line-height: 17px;
  }
  .member-data-value {
    font-size: 14px;
    line-height: 15px;
  }
  .member-note {
    font-size: 14px;
    line-height: 15px;
    margin-top: -36px;
    padding-bottom: 10px;
  }
  .member-section {
    padding-right: 20px;
    padding-left: 35px;
  }
  .flip-button-div {
    padding-top: 5px;
    text-align: right;
  }

  .contact-section {
    height: 110px;
    padding: 6px 10px 10px 10px;
    background-color: #420046;
  }
  .close-btn-margin {
    margin-right: 10px;
  }
  .contact-details {
    font-size: 16px;
  }
}

.Rectangle .edi-details {
  border-style: solid;
  border-width: 1px;
  border-color: #ffffff;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  color: #fff;
  font-size: 15px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
  .id-div-margin {
    margin-top: 90px;
  }
  .member-section {
    height: 270px;
  }

  .Rectangle {
    width: 720px;
    height: 455px;
    border-radius: 4px;
    background-color: #f1f1f1;
  }
  .Rectangle.afa-card {
    /* height: 465px;	 */
  }
  .close-btn-margin {
    margin-right: -10px;
  }

  .pre-notification {
    font-size: 14px;
  }
}

.text {
  width: 150px;
  height: 24px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.15px;
  color: #4f4f4f;
}

.Text {
  width: 78px;
  height: 24px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.15px;
  color: #533278;
}
.Text-button {
  width: 160px;
  height: 36px;
  background-color: #f1f1f1;
}

.parish-card {
  .member-note {
    // height: 22px;
    padding-left: 20px;
    strong {
      font-weight: bold;
    }

    .thanku-img {
      max-height: 40px;
      padding-top: 0px;
      // padding-bottom: 12px;
      // margin-top: -6px;
    }
  }

  .member-data-value {
    color: #420045 !important;
  }

  .footer-details {
    font-size: 14px;
  }

  .pre-notification {
    font-size: 14px !important;
    line-height: 18px;
    padding: 10px;
    height: 300px;
    p {
      margin: 0px 0px 7px 0px;
      
    }
    strong {
      font-weight: bold;
    }
  }
}
