.override-phone
  .MuiFormControl-root
  .MuiInputBase-root.MuiInput-root.MuiInput-underline.Mui-error.Mui-error.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedStart
  .MuiInput-underline.Mui-error:after {
  border-bottom-color: #3f51b5 !important;
}

.override-phone .MuiInput-underline.Mui-error:after {
  border-bottom-color: #3f51b5 !important;
}
.override-phone .MuiFormControl-root.MuiTextField-root .MuiFormLabel-root.Mui-error {
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiFormLabel-root {
  font-size: 13px !important;
  /* font-size: calc(11% + 1vw + 1vh) !important; */
  @media only screen and (min-width: 768px) {
    font-size: calc(0.9vw + 0.5vh) !important;
  }
}
.override-phone .MuiPhoneNumber-flagButton {
  display: none !important;
}
