.chatbody {
	flex-grow: 1;
	padding: 20;
    overflow-y: scroll;
	margin-top: 20px;
}
.chatbody .bubble {
	display: inline-block;
	padding: 10px;
	margin-bottom: 5px;
	border-radius: 9px;
	max-width: 600px;
}
.terms-bottom {
	width: 100vw;
	height: 40px;
	flex-grow: 0;
	background-color: #8c827a;
  }
.terms-box {
	margin-top: 5px;
	margin-left: 15px;
	width: 138px;
	height: 30px;
	flex-grow: 0;
	border-radius: 5px;
	border: solid 2px #eae8db;
}
.terms-box p {
	width: 104.6px;
	height: 14px;
	flex-grow: 0;
	margin-top: -23px;
	margin-left: 27px;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #eae8db;
  }
  .read-accepted{
	width: 522px;
	height: 24px;
	margin-top: -31px;
    margin-left: 161px;
	flex-grow: 0;
	font-family: Roboto;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: 0.44px;
	text-align: left;
	color: #e3e5e5;
  }
.chatbody .intentbubble{
	display: inline-block;
	padding: 10px;
	margin-bottom: 5px;
	border-radius: 9px;
	max-width: 147px;
}
.chatbody .intentbubble p{
	margin-bottom: 0rem !important;
	font-size: 15px;
	text-align: left;
	line-height: 1.4;
	word-break: break-word; 
	font-family: 'Roboto';
	flex-grow: 0;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.chatbody .bubble p {
	margin-bottom: 0rem !important;
	font-size: 18px;
	text-align: left;
	line-height: 1.4;
	word-break: break-word; 
	font-family: 'Roboto';
	flex-grow: 0;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	
}
.chatbody .incoming {
	text-align: left;
	display: 'flex';
}
.chatbody .incoming .bubble {
	color: #333333;
	background-color: rgb(255, 254, 254);
}
.chatbody .outgoing {
	text-align: right;
}
.chatbody .outgoing .bubble {
	color: #fdfdff;
	background-color: #2da5c8;
}
.intent{
	 background-color: '#54337A' !important;
	 color: #fff;
	 margin-right: 5px;
	 cursor:'pointer';

 }
.spinner {
	width: 70px;
	text-align: left;
  }
  
  .spinner > div {
	width: 10px;
	height: 10px;
	background-color: #333;
  
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  
  .spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
  }
  
  .spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
  }
  
  @-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% { -webkit-transform: scale(0) }
	40% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bouncedelay {
	0%, 80%, 100% { 
	  -webkit-transform: scale(0);
	  transform: scale(0);
	} 40% { 
	  -webkit-transform: scale(1.0);
	  transform: scale(1.0);
	}
  }

/* WebChatDesktop */
.dismissable-info .dismiss {
    position: absolute;
    top: 11px;
    right: 11px;
    font-size: 18px;
    color: #f2f2f2;
	cursor: pointer;
}
.dismissable-info {
	font-family: Roboto;
	text-align: left;
    flex-grow: 0;
    padding: 11.8px 41px 17.1px 12px;
    border-radius: 5px;
    background-color: #8c827a;
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: normal;
    color: #f2f2f2;
    position: relative;
	margin-bottom: 10px;
}
.web_Chat_container{
	/* width: 1440px; */
	width: 98vw;
	padding: 38px 118px 258px 40px;
	object-fit: contain;
}
.web_top_username {
	text-transform: uppercase;
	width: 35vw;
	height: 70px;
	font-family: 'Anton', sans-serif;
	font-size: 4vw;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.1;
	letter-spacing: normal;
	color: #000000;

  }
  .search-icon {
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 8; /*this will keep the icon appearing all time, even when on input::focus*/
  }
  .search-icon-caryn{
	position: absolute;
	right: 20px;
	top: 15px;
	z-index: 8;
  }
  .form-control {
	padding-left: 1.75rem;
  }
  .bottonChatIcon{
	display: none !important;
	padding-left:90vw;
  }
  .web-Chat-Icon{
	display: block;
  }
  .askCarynHeader{
	font-family: Tungsten;
	font-size: 20px;
	color: #fff
  }
  .askCarynSearchTab {
	background-color: #CDACCF;
	height: 70px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px;
	position: fixed;
	width:100vw;
	top: 80;
	z-index: 10;
  }
  .form-control .inputboxAskCaryn{
	  height: 40 !important;
	 border-radius: 40 !important;
	 padding-right: 40 !important;
	 padding-left:20 !important;

 }
.searchfont {
	line-height: 1.4;
	word-break: break-word; 
	font-family: 'Roboto';
	flex-grow: 0;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	
}

.form-control:focus {
	box-shadow: 0 0 0 0 !important;
} 
  @media only screen and (max-width: 768px) {
	.bottonChatIcon{
		display: block !important;
		padding-left:80vw;
	  }	
	  .web-Chat-Icon{
		display: none;
	  }
  }