.login {
  /* background: gray; */
  display: flex;
}

.contain22er {
  margin-top: 20px;
}
.bodyColor {
  background: gray !important;
}

.login-container {
  display: flex !important;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 50px;

  .login-card {
    /* box-shadow: 0 5px 15px rgba(0,0,0,.5) !important; */
    border-radius: 0% !important;
    width: 385px;
  }

  .left-card {
    width: 385px;
    border-radius: 0% !important;
    background: linear-gradient(60deg, #f7683a, #e8be66e0);
    .container {
      margin-top: 80px;
      padding: 10px;
      text-align: center;
      .title {
        color: #441743;
        font-weight: bold;
        font-family: 'Raleway', sans-serif;
        text-align: center;
      }
      p {
        text-align: center;
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 0;
      }

      .check-status {
        width: 65%;
        background-color: #691963;
      }

      .check-status:hover {
        color: #fff;
        background-color: #ad27a2;
      }
    }
  }
}

.logo {
  text-align: center;
}

.logo-custom {
  max-width: 80%;
  max-height: 60%;
}

.main-form {
  background: #eae8db;
  padding: 20px;
  margin-top: 20px;
}
.label-head {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  color: black;
  font-weight: 400;
}

.a-input {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  line-height: 1.42857;
}

.login form p {
  margin-bottom: 5px;
}

.a-form-ctrl {
  // position: relative;
  // margin-bottom: 5px;
  width:200px;
}

.forgot-pawd {
  color: #337ab7;
  cursor: pointer;
}
.forgot-pawd a {
  color: #337ab7;
  cursor: pointer;
}
.forgot-pawd:hover {
  text-decoration: underline;
}
.b-btn {
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0px 10px 0px;
  height: 40px;
  width: 100%;
  color: #fff;
  background-color: #4285f4;
  border-color: #2e6da4;
  background-image: none;
  border: 1px solid #2e6da4;
  border-radius: 4px;
}
.a-btn {
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0px 10px 0px;
  height: 40px;
  width: 100%;
  color: #fff;
  background-color: #fb6647;
  border-color: #2e6da4;
  background-image: none;
  border: 1px solid #2e6da4;
  border-radius: 4px;
}

.a-btn1 {
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0px 10px 0px;
  height: 40px;
  width: 220%;
  color: #fff;
  background-color: #fb6647;
  border-color: #2e6da4;
  background-image: none;
  border: 1px solid #2e6da4;
  border-radius: 4px;
}
.a-btn:hover {
  color: #fff;
  background-color: #febf42;
}
.b-btn:hover {
  color: #fff;
  background-color: #7064db;
}
.pwd-validations .valid {
  color: #19bf00;
}
.pwd-validations .invalid {
  color: #df3312;
}
.pwd-validations .pwd-validation-txt {
  margin-left: 5px;
}

.a-errorMessage {
  padding: 5px;
  font-size: 14px;
  width: 100%;
  background: #f5f5f5;
  border: 2px solid #d64958;
  color: #d64958;
  margin-bottom: 10px;
  font-weight: 400;
}

.login button.a-btn[disabled] {
  color: #ffffff85;
  pointer-events: none;
  background: #fb664778;
}

.login .a-pwd-visibility {
  cursor: pointer;
  float: right;
  position: absolute;
  top: 30px;
  right: 5px;
}

@media (max-width: 768px) {
  .left-card {
    order: 2;
  }

  .login-container {
    padding-top: 50px;

    .left-card {
      .container {
        margin-top: 0;
        padding-top: 20px;
        .title {
          display: none;
        }
        p {
          margin-top: 0;
        }
        .check-status {
          margin-top: 15px;
          width: 75%;
        }
      }
    }
  }
}

// @media (min-width: 768px) {
//   // /* new scrollbar */
//   ::-webkit-scrollbar {
//     width: 8px;
//   }

//   ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px grey;
//     border-radius: 10px;
//   }

//   ::-webkit-scrollbar-thumb {
//     background: gray;
//     border-radius: 10px;
//   }

//   ::-webkit-scrollbar-thumb:hover {
//     background: darkgrey;
//   }

//   ::-webkit-scrollbar-thumb:hover {
//     background: grey;
//   }
// }
